import React from "react";
import Layout from "../components/layout/Layout"

function BlogPost({pageContext}) {
    if(!pageContext.featured_media){
        pageContext.featured_media = {
          source_url: "https://www.youmagine.com/assets/placeholder.png"
        }
      }
    
    const date = new Intl.DateTimeFormat('id-ID', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
    }).format(new Date(pageContext.date))
    
    return(
        <Layout>
            <section id="testimonials" className="pt-5 lg:py-10">
                <div className="container mx-auto text-center">
                    <h2 className="text-3xl lg:text-5xl font-semibold mb-0" dangerouslySetInnerHTML={{__html:pageContext.title}}/>
                    <p className="text-base text-center">
                        {date} oleh Mara Juara Estate
                    </p>
                    <img className="sm:w-2/3 md:w-1/2 lg:w-1/2 items-center" src={pageContext.featured_media.source_url} />
                    <div className="sm:mx-5 md:mx-20 lg:mx-56" dangerouslySetInnerHTML={{__html:pageContext.content}}/>
                </div>
            </section>
        </Layout>
    )
}

export default BlogPost;